export enum ModuleNameEnum {
  Auth = "auth",
  Common = "common",
  Dashboard = "dashboard",
  Help = "help",
  Notification = "notification",
  Project = "project",
  Task = "task",
  Training = "training",
  User = "user",
  Profile = "profile",
}

export enum Layout {
  MainLayout = "mainLayout",
  NoLayout = "noLayout",
}

export enum SearchParamsEnum {
  Page = "page",
  ProjectName = "projectName",
  Tab = "tab",
  GraphId = "graphId",
  Need = "need",
  Search = "search",
  FilterParams = "filterParams",
}
