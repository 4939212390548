import { Card } from "@common/components";
import StatusCell from "@common/components/StatusCell";
import UploadTrainingCell from "@training/components/UploadTrainingCell";
import { Row, Col } from "antd";
import config from "config";
import { format } from "date-fns";
import { Need, CertificateInfo, UserInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  certificate: CertificateInfo;
  userData: UserInfo | undefined;
};

const EditTrainingCardOverdue: FC<PropTypes> = ({ certificate, userData }) => {
  const { t } = useTranslation("training.EditTrainingCardOverdue");

  const translatedNeed = {
    [Need.JobDepended]: t("jobDependent"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("training")}:</Col>
            <Col>{certificate.name}</Col>
          </Row>
        </Col>
        {certificate.assignedAt && (
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col className={styles.rowTitle}>{t("date")}:</Col>
              <Col>
                {format(
                  new Date(certificate.assignedAt),
                  config.DATE_WITH_DOTS,
                )}
              </Col>
            </Row>
          </Col>
        )}
        {certificate.dueTo && (
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col className={styles.rowTitle}>{t("expiry")}:</Col>
              <Col>
                {format(new Date(certificate.dueTo), config.DATE_WITH_DOTS)}
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("need")}:</Col>
            <Col>{translatedNeed[certificate.need]}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("status")}:</Col>
            <Col>
              <StatusCell status={certificate.status} />
            </Col>
          </Row>
        </Col>
        {/* will be disabled */}
        <Col span={24}>
          <Row align="middle" gutter={[12, 0]}>
            <Col className={styles.rowTitle}>{t("addRecords")}:</Col>
            <Col>
              <UploadTrainingCell
                certificate={certificate}
                userData={userData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default EditTrainingCardOverdue;
