import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { FC } from "react";

const TextArea: FC<TextAreaProps> = (props: TextAreaProps) => {
  const { showCount = true, ...restProps } = props;

  return <Input.TextArea showCount={showCount} {...restProps} />;
};

export default TextArea;
