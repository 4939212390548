import { gql } from "@apollo/client";

export const TRAINING_FRAGMENT = gql`
  fragment TrainingNode on Training {
    id
    name
    unitOfCompetency
    mandatoryPositions
    code
    type
    recurring
    description
    userId
    createdAt
    updatedAt
  }
`;
