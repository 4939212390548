import { HistoryContextValueType } from "@common/types";
import { createContext, FC, useState } from "react";

const historyContextValue: HistoryContextValueType = {
  routes: [],
  setRoutes: () => {},
};

const HistoryContext = createContext(historyContextValue);

type PropTypes = { children: JSX.Element | JSX.Element[] };

const HistoryContextProvider: FC<PropTypes> = ({ children }) => {
  const [routes, setRoutes] = useState<string[]>([]);

  return (
    <HistoryContext.Provider
      value={{
        routes,
        setRoutes,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export { HistoryContext, HistoryContextProvider };
