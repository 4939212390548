import { ModuleNameEnum } from "@common/enums";
import { useHistory } from "@common/hooks/useHistory";
import LogsServices from "@common/services/logsServices";
import { FC } from "react";
import { Link as LinkRRD, LinkProps, useLocation } from "react-router-dom";

const logsServices = new LogsServices(
  ModuleNameEnum.Common,
  "components",
  "LeaderboardInfo",
);

const Link: FC<LinkProps> = (props: LinkProps) => {
  const { addRouteToHistory } = useHistory();
  const { onClick, ...restProps } = props;
  const location = useLocation();

  const redirectUrl = `${location.pathname}${location.search}`;

  return (
    <LinkRRD
      onClick={(event) => {
        addRouteToHistory(redirectUrl);

        logsServices.logInformation({
          location: ["onClick"],
          message: `User was redirected to: ${redirectUrl}`,
        });

        onClick?.(event);
      }}
      {...restProps}
    />
  );
};

export default Link;
