import {
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
  ControlledTextArea,
} from "@common/components";
import { ModuleNameEnum } from "@common/enums";
import { useModal } from "@common/hooks/useModal";
import LogsServices from "@common/services/logsServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, message, Row } from "antd";
import config from "config";
import {
  Type,
  useCreateTrainingMutation,
  useGetPositionListQuery,
} from "graphqlTypes";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CreateTrainingType, schema } from "./schema";
import styles from "./styles.module.scss";

const logsServices = new LogsServices(
  ModuleNameEnum.Training,
  "components",
  "CreateTrainingForm",
);

const CreateTrainingForm: FC = () => {
  const { t } = useTranslation("training.CreateTrainingForm");
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
  } = useForm<CreateTrainingType>({
    resolver: yupResolver(schema),
  });
  const { hideModal } = useModal();
  const [createTrainingMutation, createTrainingMutationStatus] =
    useCreateTrainingMutation();
  const positionListQuery = useGetPositionListQuery();

  const isRecurring = getValues("isRecurring");

  const onClose = () => {
    hideModal();
    reset();
  };

  const onFormSubmit = (data: CreateTrainingType) => {
    createTrainingMutation({
      variables: {
        name: data.trainingName,
        unitOfCompetency: data.nationalUnitOfCompetency?.toString(),
        mandatoryPositions: data.mandatoryNeed || [],
        type: data.trainingType,
        recurring: data.isRecurring ? data.recurring : undefined,
        description: data.description,
        code: data.trainingCode,
      },
      refetchQueries: [
        "listTask",
        "getUserInfo",
        "getProfileInfo",
        "getLeaderboardInfo",
        "getProjectInfo",
        "listTraining",
        "getTrainingMatrix",
        "listTrainingWithoutPagination",
        "getProjectTeamInfo",
        "getGapAnalysisByDate",
      ],
      onCompleted: (data) => {
        message.success(t("trainingCreated"));
        reset();
        logsServices.logInformation({
          message: `Training (name=${data.createTraining?.name}) was created.`,
          location: ["onFormSubmit", "createTrainingMutation", "onCompleted"],
        });
        hideModal();
      },
    });
  };

  watch("isRecurring");

  const trainingTypeOptions = [
    { value: Type.External, label: t("external") },
    { value: Type.InHouse, label: t("inHouse") },
    { value: Type.Rto, label: t("RTO") },
  ];

  const positionOptions = positionListQuery.data?.getPositionList?.map(
    (positionName: string) => {
      return { value: positionName, label: positionName };
    },
  );

  return (
    <Form>
      <Row align="middle" justify="start" gutter={[12, 24]}>
        <Col span={24}>
          <h2>{t("createNewTraining")}</h2>
        </Col>
        <Col span={9}>{t("trainingName")}:</Col>
        <Col span={14}>
          <ControlledInput
            useControllerProps={{
              name: "trainingName",
              control,
            }}
            inputProps={{
              "data-testid": "training-name",
              status: errors.trainingName && "error",
            }}
          />
        </Col>
        <Col span={9}>{t("trainingCode")}:</Col>
        <Col span={14}>
          <ControlledInput
            useControllerProps={{
              name: "trainingCode",
              control,
            }}
            inputProps={{
              "data-testid": "training-code",
              status: errors.trainingCode && "error",
            }}
          />
        </Col>
        <Col span={9}>{t("nationalUnitOfCompetency")}:</Col>
        <Col span={14}>
          <ControlledInput
            useControllerProps={{
              name: "nationalUnitOfCompetency",
              control,
            }}
            inputProps={{
              "data-testid": "national-unit",
              className: styles.inputNumber,
              status: errors.nationalUnitOfCompetency && "error",
            }}
          />
        </Col>
        <Col span={9}>{t("mandatoryNeed")}:</Col>
        <Col span={14}>
          <ControlledSelect
            useControllerProps={{
              name: "mandatoryNeed",
              control,
            }}
            selectProps={{
              "data-testid": "select-position",
              popupClassName: styles.selectPopup,
              className: styles.select,
              loading: positionListQuery.loading,
              options: positionOptions,
              status: errors.mandatoryNeed && "error",
              placeholder: t("mandatoryNeedPlaceholder"),
              mode: "multiple",
            }}
          />
        </Col>
        <Col span={9}>{t("trainingType")}:</Col>
        <Col span={14}>
          <ControlledSelect
            useControllerProps={{
              name: "trainingType",
              control,
            }}
            selectProps={{
              "data-testid": "select-type",
              popupClassName: styles.selectPopup,
              allowClear: true,
              options: trainingTypeOptions,
              className: styles.select,
              status: errors.trainingType && "error",
              placeholder: t("trainingType"),
            }}
          />
        </Col>
        <Col span={9}>{t("recurring")}:</Col>
        <Col span={14}>
          <Row align="middle" justify="space-between">
            <Col>
              <ControlledCheckbox
                useControllerProps={{
                  name: "isRecurring",
                  control,
                }}
                checkboxProps={{
                  "data-testid": "recurring-checkbox",
                }}
              />
            </Col>
            {isRecurring ? (
              <Col>
                <ControlledInput
                  useControllerProps={{
                    name: "recurring",
                    control,
                  }}
                  inputProps={{
                    "data-testid": "recurring-input",
                    type: "number",
                    placeholder: t("enterFrequency"),
                    status: errors.recurring && "error",
                    suffix: t("month"),
                  }}
                />
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col span={24}>{t("description")}:</Col>
        <Col span={24}>
          <ControlledTextArea
            useControllerProps={{
              name: "description",
              control,
            }}
            textAreaProps={{
              "data-testid": "description",
              maxLength: config.LONG_DESCRIPTION_LENGTH,
              status: errors.description && "error",
            }}
          />
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button onClick={onClose}>{t("close")}</Button>
            </Col>
            <Col>
              <Button
                loading={createTrainingMutationStatus.loading}
                type="primary"
                onClick={handleSubmit(onFormSubmit)}
                data-testid="submit"
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTrainingForm;
