import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication({
  auth: {
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
    clientId: process.env.REACT_APP_AZURE_APPLICATION_ID as string,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_DIRECTORY}`,
  },
});

export default msalInstance;
