import { DeleteOutlined } from "@ant-design/icons";
import { Card, Popconfirm } from "@common/components";
import StatusCell from "@common/components/StatusCell";
import loadingService from "@common/services/loadingServices";
import UploadTrainingCell from "@training/components/UploadTrainingCell";
import { Row, Col } from "antd";
import {
  Need,
  Status,
  TrainingMatrixInfo,
  useDeleteCustomCertificateMutation,
  useDeleteTrainingCertificateMutation,
} from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type PropTypes = {
  trainingMatrixInfo: TrainingMatrixInfo;
};

const uploadableStatuses = [Status.Expired, Status.Required];

const EditMyTrainingCardOverdue: FC<PropTypes> = ({ trainingMatrixInfo }) => {
  const { t } = useTranslation("training.EditMyTrainingCardOverdue");
  const [deleteTrainingCertificate, deleteCertificateMutationStatus] =
    useDeleteTrainingCertificateMutation();
  const [deleteCustomCertificate, deleteCustomCertificateMutationStatus] =
    useDeleteCustomCertificateMutation();

  const deleteCertificate = () => {
    const variables = {
      certificateId: trainingMatrixInfo.certificate.certificateId,
    };
    const refetchQueries = [
      "getProfileInfo",
      "getTrainingMatrix",
      "getUserInfo",
    ];

    if (trainingMatrixInfo.certificate.need === Need.Custom) {
      return deleteCustomCertificate({
        variables,
        refetchQueries,
      });
    }
    deleteTrainingCertificate({
      variables,
      refetchQueries,
    });
  };
  const { certificate } = trainingMatrixInfo;

  const translatedNeed = {
    [Need.JobDepended]: t("jobDependent"),
    [Need.Mandatory]: t("mandatory"),
    [Need.Custom]: t("custom"),
  };

  const isLoading = loadingService.checkLoading([
    deleteCustomCertificateMutationStatus.loading,
    deleteCertificateMutationStatus.loading,
  ]);

  const isUploadable = uploadableStatuses.some((uploadableStatus) => {
    return uploadableStatus === certificate.status;
  });

  return (
    <Card className={styles.card} loading={isLoading}>
      <Row justify="space-between">
        <Col span={20}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("training")}:</Col>
                <Col>{certificate.name}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("need")}:</Col>
                <Col>{translatedNeed[certificate.need]}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("project")}:</Col>
                <Col>{certificate.name}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]}>
                <Col className={styles.rowTitle}>{t("status")}:</Col>
                <Col>
                  <StatusCell status={certificate.status} />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 0]} align="middle">
                {/* <Col className={styles.rowTitle}>{t("viewRecord")}:</Col> */}
                {isUploadable ? (
                  <Col className={styles.rowTitle}>{t("addRecords")}:</Col>
                ) : (
                  <Col className={styles.rowTitle}>{t("viewRecord")}:</Col>
                )}
                <Col className={styles.archivedStatus}>
                  <UploadTrainingCell
                    userData={undefined}
                    certificate={certificate}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {certificate.certificateLink && (
          <Col>
            <Popconfirm
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={deleteCertificate}
              title={t("deleteConfirmation")}
            >
              <DeleteOutlined className={styles.deleteIcon} />
            </Popconfirm>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default EditMyTrainingCardOverdue;
