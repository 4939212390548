import { HistoryContext } from "@common/contexts/HistoryContext";
import config from "config";
import RoutePaths from "config/routePaths";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useHistory = () => {
  const modalContext = useContext(HistoryContext);
  const navigate = useNavigate();

  const { routes, setRoutes } = modalContext;

  const navigateToLastRoute = () => {
    if (routes.length - 1 < 0) {
      return navigate(RoutePaths.Profile);
    }

    const lastRoute = routes[routes.length - 1];

    setRoutes((routes) => {
      return routes.slice(0, routes.length - 1);
    });

    navigate(lastRoute);
  };

  const addRouteToHistory = (route: string) => {
    if (routes.length > config.MAX_HISTORY_COUNT) {
      return setRoutes((routes) => {
        return [...routes.slice(1, routes.length - 1), route];
      });
    }

    setRoutes((routes) => {
      return [...routes, route];
    });
  };

  return { navigateToLastRoute, addRouteToHistory };
};
