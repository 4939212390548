import { LogPropsType } from "@common/types";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "providers/appInsight";
import msalInstance from "providers/msal";

class LogsServices {
  constructor(...locations: string[]) {
    this.location = [...locations];
  }

  private location: string[] = [];

  private addLogToTrackTrace = (
    logData: LogPropsType,
    severityLevel: SeverityLevel,
  ) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const account = activeAccount || accounts[0];
    appInsights.trackTrace({
      message: `USER_ID=[${
        account?.homeAccountId
      }] LOCATION=[${this.location.join("/")}/${logData.location?.join(
        "/",
      )}] MESSAGE=[${logData.message}]`,
      severityLevel,
    });
  };

  logCritical = (logData: LogPropsType) => {
    this.addLogToTrackTrace(logData, SeverityLevel.Critical);
  };

  logError = (logData: LogPropsType) => {
    this.addLogToTrackTrace(logData, SeverityLevel.Error);
  };

  logVerbose = (logData: LogPropsType) => {
    this.addLogToTrackTrace(logData, SeverityLevel.Verbose);
  };

  logInformation = (logData: LogPropsType) => {
    this.addLogToTrackTrace(logData, SeverityLevel.Information);
  };

  logWarning = (logData: LogPropsType) => {
    this.addLogToTrackTrace(logData, SeverityLevel.Warning);
  };
}

export default LogsServices;
