enum RoutePaths {
  SignIn = "/signin",
  Users = "/users",
  UserProfile = "/users/:userId",
  Profile = "/",
  Projects = "/projects",
  Project = "/projects/:projectId",
  UserCertificates = "/projects/:projectId/:userId",
  Training = "/training",
  MyTrainingMatrix = "/myTrainingMatrix",
  Dashboard = "/dashboard",
  Help = "/help",
  HelpQuestion = "/help/:questionId",
  Tasks = "/tasks",
  UnknownPath = "*",
  Notifications = "/notifications",
}

export default RoutePaths;
