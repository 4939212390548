import { Role } from "graphqlTypes";

export default {
  FILE_SIZE: 10, // MB
  CERTIFICATE_FILE_TYPES: ["pdf", "jpg", "jpeg", "png"],
  EDUCATION_FILE_TYPES: ["pdf", "jpg", "jpeg", "png"],
  HELP_FILE_TYPES: ["pdf", "jpg", "jpeg", "png"],
  AZURE_SCOPES: ["User.Read.All", "Directory.Read.All"],
  TABLE_LIMIT: 10,
  // User without role will get this role permissions
  DEFAULT_ROLE: Role.User,
  ACTIVE_CHART_COLOR: "#9CE5C0",
  REACTIVE_CHART_COLOR: "#FEAEAE",
  DEFAULT_DEBOUNCE_TIME: 500,
  LANGUAGE: "en",
  PHONE_SCREEN_SIZE: 1080,
  MAX_HISTORY_COUNT: 20,
  DATE_WITH_DOTS: "dd.MM.y",
  DATE_AND_TIME: "yyyy-MM-dd'T'hh:mm:ss",
  REVERSED_DATE_WITH_DASHES: "yyyy-MM-dd",
  WEB_SOCKET_RECONNECT_MILLISECONDS: 10000,
  IMG_RESOLUTION_HIGHT: "648x648",
  IMG_RESOLUTION_MIDDLE: "360x360",
  IMG_RESOLUTION_LOW: "48x48",
  DEFAULT_STAGE_FILTERS: ["02 Being constructed"],
  SHORT_DESCRIPTION_LENGTH: 200,
  LONG_DESCRIPTION_LENGTH: 2000,
  MIN_YEAR: new Date().getFullYear() - 80,
  MAX_YEAR: new Date().getFullYear() + 1,
  ROLES_WITH_EXCEL_EXPORT: [Role.Admin, Role.Manager, Role.SuperAdmin],
};
