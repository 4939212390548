import { FileOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "@common/components";
import { useModal } from "@common/hooks/useModal";
import UploadExistedCertificateForm from "@training/components/UploadExistedCertificateForm";
import { Col, Row } from "antd";
import { CertificateInfo, Status, UserInfo } from "graphqlTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PropTypes = {
  certificate: CertificateInfo;
  userData: UserInfo | undefined;
};

const uploadableStatuses = [Status.Expired, Status.Required];

const UploadTrainingCell: FC<PropTypes> = ({ certificate, userData }) => {
  const { hideModal, setModalProps } = useModal();
  const { t } = useTranslation("training.UploadTrainingCell");

  const onUploadCertificateClick = () => {
    setModalProps({
      footer: false,
      open: true,
      onCancel: hideModal,
      children: (
        <UploadExistedCertificateForm
          key={certificate.trainingId}
          userData={userData}
          certificate={certificate}
        />
      ),
    });
  };

  const isUploadable = uploadableStatuses.some((uploadableStatus) => {
    return uploadableStatus === certificate.status;
  });

  // return certificate.certificateLink ? (
  //   <a
  //     data-cy="certificate-download"
  //     target="_blank"
  //     href={certificate.certificateLink}
  //     rel="noreferrer"
  //   >
  //     <FileOutlined />
  //   </a>
  // ) : (
  //   <>-</>
  // );

  // will be disabled
  return (
    <Row align="middle" justify="center" gutter={[0, 24]}>
      {certificate.certificateLink && certificate.status !== Status.Expired && (
        <Col>
          <a
            data-cy="certificate-download"
            target="_blank"
            href={certificate.certificateLink}
            rel="noreferrer"
          >
            <FileOutlined />
          </a>
        </Col>
      )}
      {isUploadable && (
        <Col>
          <Button
            data-cy="upload-existed-certificate"
            type="link"
            icon={<PlusOutlined />}
            onClick={onUploadCertificateClick}
          >
            {t("upload")}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default UploadTrainingCell;
